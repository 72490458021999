import { ServerResponse } from 'http';

import React from 'react';
import Router from 'next/router';

import { isBrowser, isNotNullish, RequestError } from '@tager/web-core';

import ErrorPage from '@/pages/_error';
import {
  DealerOptionType,
  DealerSelectOptionType,
  OptionType,
  SelectOptionType,
  WebsiteSourceType,
} from '@/typings/common';

export function convertSlugToPath(
  slug: Array<string> | string | undefined
): string {
  if (!slug) return '/';

  if (Array.isArray(slug)) {
    return slug.map(convertSlugToPath).join('');
  }

  return '/' + slug;
}

export function convertAliasToPath(
  alias: Array<string> | string | undefined
): string {
  if (!alias) return '';
  const convertString = convertSlugToPath(alias);

  return convertString.substring(convertString.indexOf('/') + 1);
}

export const getCurrentPath = (
  pathName: string,
  slug: string[] | string | undefined
): string => {
  return `${
    pathName === '/[[...slug]]' ? '' : `/${pathName.split('/')[1]}`
  }${convertSlugToPath(slug)}`;
};

export function convertErrorToProps(
  error: Error | RequestError
): React.ComponentProps<typeof ErrorPage> {
  if (error && 'status' in error) {
    return { statusCode: error.status.code, title: error.status.text };
  }

  return { err: error, statusCode: 500 };
}

type ParamValue = string | (string | null)[];

type QueryType = { [key in string]?: ParamValue };

export function getFilterParamAsStringArray(
  query: QueryType,
  key: string
): Array<string> {
  const queryValue = query[key];

  if (Array.isArray(queryValue)) {
    return queryValue.filter(isNotNullish);
  }

  if (queryValue) {
    return [queryValue];
  }

  return [];
}

export const getWebsiteSource = (): WebsiteSourceType => {
  return (
    (process.env.NEXT_PUBLIC_WEBSITE_SOURCE as WebsiteSourceType) ?? 'ATLANTM'
  );
};

export const getSubdomain = (): string =>
  isBrowser()
    ? window.location.hostname.substring(
        0,
        window.location.hostname.indexOf('.')
      )
    : '';

export const redirect = (
  location: string,
  options?: {
    hardReload?: boolean;
  },
  res?: ServerResponse,
  code: number = 302
): void => {
  if (res) {
    res.writeHead(code, { Location: location }).end();
  } else {
    if (options?.hardReload) {
      window.location.href = location;
    } else {
      Router.replace(location);
    }
  }
};

export const getPhoneLink = (phone: string) => {
  const formattedPhone = phone.replace(/[^0-9]+/g, '');

  return `tel:${formattedPhone.length <= 4 ? '' : '+'}${formattedPhone}`;
};

export const getCarsStockPriceText = (
  phone?: string,
  isPartnerCar?: boolean
): string => {
  return `<p style="margin-left:0px;">
            Сформируйте заказ по номеру:&nbsp;
          </p>
          <p style="margin-left:0px;">
            <a href="${getPhoneLink(
              (!isPartnerCar ? phone : '+375 44 502-95-43') ?? ''
            )}">${!isPartnerCar ? phone : '+375 44 502-95-43'}</a>
          </p>`;
};

export const getYouTubeId = (url: string): string => {
  const regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : url;
};

export const getAnnuityCreditPayment = (
  rate: number,
  amount: number,
  period: number
): number => {
  const resultRate = rate / 12 / 100;

  return Number(
    (
      amount *
      ((resultRate * Math.pow(1 + resultRate, period)) /
        (Math.pow(1 + resultRate, period) - 1))
    ).toFixed(2)
  );
};

export const getCreditPeriods = (
  minPeriod: number,
  maxPeriod: number
): number[] => {
  let legendsValues: number[] = [minPeriod];
  let prevValue = 0;

  if (maxPeriod % 12 !== 0 && maxPeriod % 6 !== 0 && prevValue < maxPeriod) {
    return legendsValues;
  }

  while (prevValue < maxPeriod) {
    if (maxPeriod % 12 === 0 || maxPeriod % 6 === 0) {
      const calculateValue = prevValue + 12;
      if (!legendsValues.includes(calculateValue)) {
        legendsValues.push(calculateValue);
      }

      prevValue = calculateValue;
    }
  }

  if (!legendsValues.includes(maxPeriod)) {
    legendsValues.push(maxPeriod);
  }

  return legendsValues;
};

export const getDealersOptions = (
  dealers: DealerOptionType[]
): DealerSelectOptionType[] => {
  return dealers && dealers.length !== 0
    ? dealers.map((dealer) => ({
        label: `${dealer.address}, ${dealer.name} `,
        value: String(dealer.id),
        crmId: dealer.crmId,
      }))
    : [];
};

export const getSelectOptions = (options: SelectOptionType[]): OptionType[] => {
  return options && options.length !== 0
    ? options.map((option) => {
        const [value] = Object.values(option);
        return {
          label: value,
          value,
        };
      })
    : [];
};
